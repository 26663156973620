import * as React from 'react'

import { Box, Typography } from '@mui/material'

import { SeoHeaders } from 'shared/components'

const IndexPage = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 2,
      }}
    >
      <SeoHeaders description='Plataforma pausada' />
      <Typography fontFamily='monospace'>
        Plataforma pausada
      </Typography>
      <Typography fontFamily='monospace'>
        Si eres cliente y tienes consultas escribe a
        {' '}
        <a href='mailto:contacto@aicapital.cl'>contacto@aicapital.cl</a>
      </Typography>
    </Box>
  )
}

export default IndexPage
